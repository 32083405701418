@import url('https://fonts.googleapis.com/css2?family=Zen+Kurenaido&display=swap');

html {
  font-family: 'Zen Kurenaido', sans-serif;
}

html,
body,
ul,
li {
  margin: 0;
  padding: 0;
}
